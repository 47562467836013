.historiesContainer {
    height: 400px;
    width: 100%;
    overflow-y: auto;
}

.historyTableHead {
    background-color: var(--primary);
    color: var(--white);
}

.historiesContainer::-webkit-scrollbar {
    display: none;
}

.historiesInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}