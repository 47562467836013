.footerWrapper {
    background-color: var(--primary);
    height: auto;
}

.linksContainer {
    color: var(--white);
    margin-top: 32px;
    margin-left: 16px;
    font-weight: 600;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
}

.socialMediaContainer {
    margin: 16px 0px;
    padding: 16px 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #fff;
}

.copyRight {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    background-color: var(--primary);
    height: 40px;
    font-weight: 600;

}

.footerText {
    font-size: 14px;
    text-align: left;
    margin-top: 8px
}

.SocialMediaIcons {
    margin-right: 16px;
    cursor: pointer
}

@media screen and (max-width:767.5px) {
    .socialMediaWrapper {
        text-align: center !important;
    }

    .footerText {
        text-align: center;
        margin-top: 16px;
    }
}

.logo {
    width: 180px;
    height: 30px;
    background-image: url('../../assets/images/betearly-logo.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: -8px;
}