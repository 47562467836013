.contactUsWrapper {
    padding: 16px;
    width: 100%;
}

.contactButton,
.contactButton:hover,
.contactButton:focus {
    width: 100%;
    background-color: var(--primary);
    color: var(--white);
    font-weight: bold;
    border-radius: 100px;
    border: none;
}

.contactUsText {
    color: var(--primary);
    font-weight: bold;
}