.transactionsContainer {
    height: 400px;
    width: 100%;
    overflow-y: auto;
}

.transactionTableHead {
    background-color: var(--primary);
    color: var(--white);
}

.transactionsContainer::-webkit-scrollbar {
    display: none;
}

.transactionsInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transactionSentence {
    color: var(--purple);
    font-weight: bold;
}