.newsImage {
    margin-bottom: 8px;
}

.newsImage img {
    height: 250px;
}

.newsContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 500px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.newsBody {
    padding: 16px;
    width: 100%;
}

.newsBodyTitle {
    color: var(--gray);
}

.newsDate {
    padding: 8px 0px;
    font-size: 12px;
    font-weight: bold;
    margin: 0;
}

.newsReadMoreButton,
.newsReadMoreButton:hover,
.newsReadMoreButton:focus {
    width: 100%;
    border-radius: 100px !important;
    margin: 8px 0px;
    background-color: var(--third);
    border: none;
}

.newsReadMoreButton a,
.newsReadMoreButton:hover a,
.newsReadMoreButton:focus a {
    color: var(--primary) !important;
    text-decoration: none !important;
}