.reviewsWrapper {
    height: auto;
    padding: 100px 0px;
}

.reviewsSentence {
    padding: 16px;
    margin: 0;
    font-size: 75px;
    color: var(--fourth);
}

.reviewsContainer {
    margin: 16px;
    height: 500px;
    overflow-y: auto;
}

.reviewsContainer::-webkit-scrollbar {
    display: none;
}

.reviewContainer {
    border: 1px solid var(--primary);
    border-radius: 24px;
    padding: 16px 24px;
    margin: 8px;
    display: flex;
}

.reviewImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.reviewImage img {
    border-radius: 8px;
}

.reviewBodyContainer {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
}

.reviewBodyContainer h4 {
    color: var(--primary);
    font-weight: bold;
}

@media screen and (max-width:1250px) {
    .reviewsSentence {
        font-size: 65px;
    }
}

@media screen and (max-width:992px) {
    .reviewsSentence {
        font-size: 35px;
    }
}

@media screen and (max-width:400px) {
    .reviewsSentence {
        font-size: 25px;
    }
}