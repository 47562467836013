.sportsLinkWrapper {
    padding: 16px;
    margin-bottom: 32px;
}

.sportsLinkContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.sportsLinkTitle {
    color: var(--primary);
    padding: 8px;
}

.sportsLinkTitle h4 {
    font-weight: bold !important;
}

.sportsLinkContainer {
    padding: 8px;
}

.sportsLink {
    color: var(--fifth) !important;
    font-weight: bold;
    border: 1px solid var(--lightGray);
    padding: 12px 24px;
    border-radius: 100px;
    margin: 8px;
    cursor: pointer;
    font-size: 20px;
}

.sportsLink:hover {
    background-color: var(--fourth);
}