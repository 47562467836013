.tableSportsTitle {
     color: var(--primary);
     margin: 24px 0px;
     font-weight: bold;
     padding: 8px;
     border-radius: 8px;
}

.gameTableContainer {
     border-radius: 0px;
     overflow-y: auto;
     padding: 0px;
}

.gameCard {
     padding: 16px;
     margin-bottom: 16px;
     border-right: 1px solid var(--lightGray);
}

.gameCard p {
     display: flex;
     justify-content: space-between;
     font-size: 16px;
     font-weight: bold;
}

.gameTime {
     font-size: 14px !important;
     font-weight: bold !important;
}

.divider {
     width: 100%;
     height: 1px;
     background-color: var(--lightGray);
     margin: 8px 0px;
}

.homeTeam {
     background-color: var(--secondary);
     color: var(--primary);
     font-size: 14px;
     padding: 8px 12px;
     border-radius: 100px;
}

.awayTeam {
     background-color: var(--primary);
     color: var(--white);
     font-weight: 400;
     font-size: 14px;
     padding: 8px 12px;
     border-radius: 100px;
}