.usaLeagueWrapper {
    width: 100%;
    margin-top: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--lightGray);
    border-top: 1px solid var(--lightGray);
}


.usaLeaguesContainer {
    padding: 8px;
    display: flex;
    overflow-x: auto;
}

.usaLeagueContainer {
    display: flex;

    border: 1px solid var(--lightGray);
    border-radius: 100px;
    width: 100%;
    margin: 8px;
    padding: 8px 16px;
    cursor: pointer;
}

.usaLeagueContainer:hover {
    background-color: var(--secondary);
}

.usaLeagueText {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0 !important;
    font-weight: bold;
    margin-left: 16px;
    color: var(--primary) !important;
}